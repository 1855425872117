import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageMap } from '@ngx-pwa/local-storage';

import { environment } from 'src/environments/environment';
import { Users } from '../models/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: any;

  currentPath: any;

  public user = new BehaviorSubject<any>(null);
  users$ = this.user.asObservable();

  users!: Users;

  constructor(
    private http: HttpClient,
    private storage: StorageMap
  ) {}

  validationToken() {
    const helper = new JwtHelperService();
  }

  login(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return lastValueFrom(
      this.http.post<any>(`${environment.apiUrl}/app/auth/login`, data, {
        headers,
      })
    );
  }

  loginAdfs() {
    const headers = new HttpHeaders({
      AppID: environment.appID,
    });
    const isLocal = window.location.hostname === 'localhost';
    console.log('isLocal', isLocal);

    let param = new HttpParams({
      fromObject: {
        relayState: `https://mie2024.unictive.net/auth/login${isLocal ? '/dev' : ''}`,
        tokenDuration: '3600',
      },
    });
    return lastValueFrom(
      this.http.get<any>(
        `${environment.apiUrladfs}/adfs/auth` + (param ? '?' + param : ''),
        { headers: headers }
      )
    );
  }

  GET_UserData() {
    lastValueFrom(
      this.http.get<any>(`${environment.apiUrl}/app/auth/info`)
    ).then((res) => {
      if (res.success) {
        let response = res.data;
        response.initial = response.name.split(' ').map((n: string) => n[0]).join('').substring(0, 2);
        this.user.next(response);
        this.users = res.data;
        lastValueFrom(this.storage.set('users', res.data));
      }
    });
  }

  GET_UserDataAdfs(token: string) {
    const headers = new HttpHeaders({
      AppID: environment.appID,
      Authorization: `Bearer ${token}`, // Assuming you have a method to get the token
    });
    return lastValueFrom(
      this.http.get<any>(`${environment.apiUrladfs}/adfs/user-details`, {
        headers: headers,
      })
    );
  }

  userdata() {
    return lastValueFrom(
      this.http.get<any>(`${environment.apiUrl}/app/v1/am/admin/info`)
    ).then((res) => {
      if (res.success) {
        this.user.next(res.data);
        this.users = res.data;
        return res.data;
      }
    });
  }

  logout() {
    lastValueFrom(this.storage.delete('token-x'));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  logout_end_session() {
    lastValueFrom(this.storage.delete('token-x'));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  logoutAdfs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.storage.get('token-adfs').subscribe((tokenAdfs: any) => {
        const token = tokenAdfs as string;
        const headers = new HttpHeaders({
          'AppID': environment.appID,
          'Authorization': `Bearer ${token}`
        });
        const isLocal = window.location.hostname === 'localhost';
        let param = new HttpParams({
          fromObject: {
            'relayState': `https://mie2024.unictive.net/auth/login${isLocal ? '/dev' : ''}`,
            'tokenDuration': '3600',
          }
        });
        lastValueFrom(this.http.get<any>(`${environment.apiUrladfs}/adfs/logout` + (param ? '?' + param : ''), { headers: headers }))
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    });
  }
}
